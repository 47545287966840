import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 300.000000 300.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">

<path d="M582 1737 c-161 -161 -171 -174 -155 -189 13 -14 14 -20 4 -32 -10
-11 -9 -19 1 -36 11 -17 11 -22 -2 -30 -8 -5 -11 -13 -7 -17 4 -4 82 -83 174
-176 l168 -167 170 170 c158 158 169 171 157 191 -10 16 -11 24 -1 35 9 11 8
18 -5 33 -17 18 -16 20 1 30 16 9 -3 31 -157 186 l-175 175 -173 -173z m338
-11 c112 -111 159 -163 155 -174 -4 -12 -47 25 -160 138 -85 85 -156 152 -159
150 -3 -3 -72 -71 -154 -152 -114 -113 -152 -144 -161 -135 -9 9 26 49 151
174 89 89 163 161 165 160 1 -1 75 -73 163 -161z m-11 -50 c93 -93 141 -148
136 -156 -5 -8 -53 34 -146 126 l-139 139 -136 -137 c-122 -123 -154 -148
-154 -118 0 12 272 288 285 289 6 1 75 -64 154 -143z m-15 -45 l129 -129 -133
-132 -132 -133 -131 131 -130 130 129 130 c71 72 131 131 134 131 3 1 63 -57
134 -128z m-288 -262 l152 -151 137 137 c100 99 140 134 148 126 8 -8 -29 -52
-136 -159 l-147 -147 -144 140 c-86 84 -143 147 -141 156 2 11 -3 15 -15 12
-10 -3 -21 0 -24 6 -8 12 0 31 12 31 4 0 75 -68 158 -151z m470 140 c10 -17
-13 -36 -27 -22 -12 12 -4 33 11 33 5 0 12 -5 16 -11z m-150 -238 l-165 -165
-160 160 c-88 87 -161 165 -161 172 0 30 34 3 172 -135 l148 -148 152 152
c121 121 155 150 166 141 11 -9 -19 -44 -152 -177z"/>
<path d="M651 1538 c4 -21 -12 -88 -22 -94 -20 -12 -7 -24 24 -24 28 0 33 4
33 22 -1 17 6 24 28 28 16 3 35 15 43 26 12 19 12 24 -2 38 -20 20 -107 23
-104 4z m69 -27 c0 -20 -17 -37 -27 -28 -7 8 7 47 18 47 5 0 9 -9 9 -19z"/>
<path d="M780 1525 c0 -14 -7 -42 -15 -62 -8 -19 -15 -37 -15 -39 0 -2 24 -4
54 -4 66 0 111 34 75 56 -8 5 -6 10 5 16 41 23 4 58 -63 58 -37 0 -41 -2 -41
-25z m58 -62 c-5 -25 -28 -28 -28 -4 0 12 6 21 16 21 9 0 14 -7 12 -17z"/>
<path d="M1180 1519 c0 -5 5 -9 10 -9 6 0 10 -24 10 -54 0 -30 -4 -58 -10 -61
-20 -12 -9 -25 20 -25 17 0 30 5 30 10 0 6 -4 10 -10 10 -21 0 -10 39 12 42
12 2 29 15 38 30 13 25 13 29 -2 44 -17 17 -98 28 -98 13z m78 -21 c16 -16 15
-23 -4 -42 -21 -21 -34 -12 -34 25 0 30 17 38 38 17z"/>
<path d="M1334 1473 c-13 -32 -29 -66 -34 -75 -15 -26 -13 -28 20 -28 17 0 30
5 30 10 0 6 -7 10 -16 10 -11 0 -14 5 -10 16 3 9 6 18 6 20 0 2 11 4 24 4 24
0 46 -32 27 -38 -23 -8 -10 -22 19 -22 17 0 30 5 30 10 0 6 -4 10 -10 10 -5 0
-19 32 -30 70 -11 39 -23 70 -26 70 -3 0 -16 -26 -30 -57z m36 -14 c0 -11 -7
-19 -16 -19 -11 0 -14 5 -10 16 3 9 6 20 6 25 0 6 5 7 10 4 6 -3 10 -15 10
-26z"/>
<path d="M1420 1518 c0 -4 4 -8 10 -8 13 0 50 -74 50 -100 0 -11 -4 -20 -10
-20 -5 0 -10 -4 -10 -10 0 -5 14 -10 30 -10 17 0 30 5 30 10 0 6 -4 10 -10 10
-5 0 -10 11 -10 24 0 29 37 96 53 96 18 0 -2 14 -23 15 -11 1 -19 -3 -18 -9 3
-17 -12 -56 -22 -56 -12 0 -21 30 -15 50 4 13 -1 17 -25 17 -16 0 -30 -4 -30
-9z"/>
<path d="M1578 1458 c3 -46 1 -68 -7 -68 -6 0 -11 -4 -11 -10 0 -19 79 -11
100 10 24 24 25 34 5 50 -11 9 -12 17 -5 30 6 10 7 26 4 34 -6 16 -11 17 -62
20 l-27 1 3 -67z m66 36 c8 -21 -1 -34 -25 -34 -14 0 -19 7 -19 25 0 29 34 36
44 9z m4 -81 c3 -19 -1 -23 -22 -23 -21 0 -26 5 -26 26 0 21 4 25 23 22 14 -2
23 -11 25 -25z"/>
<path d="M1700 1518 c0 -4 5 -8 10 -8 6 0 10 -27 10 -60 0 -33 -4 -60 -10 -60
-5 0 -10 -4 -10 -10 0 -5 14 -10 30 -10 17 0 30 5 30 10 0 6 -4 10 -10 10 -6
0 -10 27 -10 60 0 33 4 60 10 60 6 0 10 4 10 8 0 5 -13 9 -30 9 -16 0 -30 -4
-30 -9z"/>
<path d="M1795 1448 l0 -78 41 0 c29 0 44 5 48 16 9 23 7 34 -4 34 -5 0 -10
-7 -10 -15 0 -9 -9 -15 -25 -15 -24 0 -25 3 -25 54 0 30 5 58 10 61 18 11 11
23 -12 21 -22 -1 -23 -5 -23 -78z"/>
<path d="M1903 1518 c-2 -5 1 -8 6 -8 15 0 14 -113 -1 -128 -9 -9 -1 -12 39
-12 46 0 51 2 55 25 6 27 -6 34 -16 10 -3 -8 -15 -15 -26 -15 -18 0 -20 6 -20
60 0 33 4 60 10 60 6 0 10 4 10 9 0 11 -50 9 -57 -1z"/>
<path d="M2020 1518 c0 -5 5 -8 10 -8 6 0 10 -24 10 -54 0 -30 -4 -58 -10 -61
-23 -14 -7 -25 39 -25 41 0 51 3 56 21 8 25 -2 39 -11 15 -3 -9 -17 -16 -30
-16 -19 0 -24 5 -24 25 0 20 5 25 25 25 14 0 25 5 25 10 0 6 -11 10 -25 10
-20 0 -25 5 -25 25 0 30 33 35 49 8 9 -16 10 -15 11 8 0 24 -3 25 -50 25 -27
-1 -50 -4 -50 -8z"/>
<path d="M2168 1458 c3 -46 1 -68 -7 -68 -6 0 -11 -4 -11 -10 0 -5 14 -10 30
-10 17 0 30 5 30 10 0 6 -4 10 -10 10 -5 0 -10 11 -10 25 0 35 14 31 39 -10
19 -32 51 -48 51 -25 0 6 -4 10 -10 10 -5 0 -17 11 -26 25 -15 22 -15 26 0 40
18 19 21 51 5 60 -6 4 -27 8 -48 9 l-36 2 3 -68z m68 37 c7 -18 -12 -45 -32
-45 -9 0 -14 11 -14 30 0 23 4 30 20 30 11 0 23 -7 26 -15z"/>
<path d="M2318 1519 c-31 -17 -22 -55 18 -78 40 -24 41 -44 2 -49 -21 -3 -27
2 -30 20 -4 20 -5 21 -12 6 -13 -29 1 -48 33 -48 34 0 64 25 59 49 -2 8 -18
25 -35 36 -36 23 -44 55 -13 55 10 0 23 -8 29 -17 9 -17 10 -17 17 1 12 30
-32 47 -68 25z"/>
<path d="M1561 1269 c-14 -42 -14 -49 4 -49 9 0 15 9 15 24 0 13 3 31 6 40 3
9 2 16 -4 16 -6 0 -15 -14 -21 -31z"/>
<path d="M1630 1291 c0 -5 -9 -11 -21 -14 -28 -7 -22 -49 7 -53 17 -3 23 3 28
27 8 42 8 49 -4 49 -5 0 -10 -4 -10 -9z"/>
<path d="M1666 1276 c-3 -13 -9 -31 -12 -40 -4 -11 -1 -16 10 -16 9 0 16 8 17
18 0 9 2 27 5 40 5 30 -12 28 -20 -2z"/>
<path d="M1798 1283 c-16 -8 -28 -22 -28 -33 0 -10 -5 -22 -11 -26 -7 -4 -10
4 -7 22 3 25 -1 29 -24 31 -18 1 -28 -3 -28 -12 0 -7 -3 -21 -7 -31 -5 -14 -3
-16 12 -11 10 5 28 1 42 -9 15 -9 34 -13 49 -10 30 8 31 26 2 27 l-23 0 23 9
c12 5 22 16 22 24 0 8 5 16 12 18 7 3 8 7 3 9 -6 3 -22 -1 -37 -8z"/>
<path d="M1986 1276 c-3 -13 -9 -31 -12 -40 -4 -10 -1 -16 9 -16 12 0 27 38
27 71 0 19 -18 8 -24 -15z"/>
<path d="M2136 1276 c-3 -13 -9 -31 -12 -40 -4 -10 -1 -16 9 -16 12 0 27 38
27 71 0 19 -18 8 -24 -15z"/>
<path d="M2090 1264 c-14 -35 -13 -44 5 -44 8 0 15 9 15 19 0 11 3 26 6 35 3
9 1 16 -5 16 -6 0 -16 -12 -21 -26z"/>
<path d="M1293 1240 c-12 -47 -2 -51 41 -17 34 27 30 57 -7 57 -18 0 -25 -8
-34 -40z"/>
<path d="M1370 1254 c-13 -33 -13 -34 5 -34 8 0 15 7 15 15 0 9 7 18 15 21 22
9 18 24 -5 24 -13 0 -24 -10 -30 -26z"/>
<path d="M1430 1266 c-7 -9 -10 -23 -6 -31 11 -29 60 -10 54 21 -4 24 -31 29
-48 10z"/>
<path d="M1496 1273 c-10 -10 -7 -41 5 -48 12 -8 51 24 46 38 -4 12 -41 19
-51 10z"/>
<path d="M1851 1258 c-10 -27 0 -41 25 -36 27 5 30 58 3 58 -11 0 -23 -10 -28
-22z"/>
<path d="M1920 1265 c-15 -18 -6 -45 13 -45 20 0 40 27 33 45 -7 19 -30 19
-46 0z"/>
<path d="M2019 1265 c-1 -8 -2 -20 -3 -26 -1 -7 11 -13 27 -15 21 -2 27 2 27
17 0 10 0 22 1 26 2 20 -20 11 -24 -9 l-4 -23 -2 23 c-1 24 -20 31 -22 7z"/>
<path d="M2175 1266 c-20 -15 -15 -46 8 -46 20 0 40 27 33 45 -7 19 -17 19
-41 1z"/>
<path d="M2231 1249 c-8 -23 -7 -29 5 -29 7 0 14 6 14 14 0 8 5 18 11 21 6 4
9 0 7 -10 -2 -10 3 -20 10 -22 8 -3 12 6 12 25 0 25 -4 30 -24 30 -18 0 -28
-8 -35 -29z"/>
<path d="M2313 1261 c2 -11 0 -22 -7 -24 -6 -3 -3 -8 7 -12 21 -9 34 1 39 30
2 15 -3 21 -21 23 -20 3 -23 0 -18 -17z"/>
<path d="M1184 1241 c-12 -12 0 -18 36 -18 22 0 40 5 40 11 0 10 -67 16 -76 7z"/>
</g>
</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
